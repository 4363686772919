import { graphql } from "gatsby";
import * as React from "react";
import { Block, Columns, Container } from "react-bulma-components";

import { ImageGallery, Layout, MemberSquare, Subtitle, Title } from "../components/index";

export const fragment = graphql`
    fragment memberData on MembersJson {
        members {
            name
            role
            imageLarge {
                ...fragmentLargeLightboxImage
            }
            imagePreview {
                ...blurredPlaceholder
            }
        }
    }
`;

export const query = graphql`
    query QueryMembers {
        membersCurrent: membersJson(section: {eq: "current"}) {
            ...memberData
        }

        membersPast: membersJson(section: {eq: "past"}) {
            ...memberData
        } 
    }
`;

function createMemberSection(title, sectionLabel, members, uniqueGalleryName) {
    members.forEach(member => {
        member.alt = `Headshot of ${member.name}`;
    });

    return (
        <Block renderAs="section" aria-label={sectionLabel}>
            <Subtitle>{title}</Subtitle>
            <Container>
                <Columns textAlign="center" px={5} justifyContent="center">
                    <ImageGallery
                        uniqueGalleryName={uniqueGalleryName}
                        photosLarge={members.map((member) => { 
                            return {
                                url: member.imageLarge.childImageSharp.fluid.src,
                                alt: member.alt
                            };
                        })}
                    >
                        {members.map(({ name, role, imagePreview, alt }, i) => {
                            return (
                                <MemberSquare
                                    name={name}
                                    role={role}
                                    imagePreview={imagePreview}
                                    key={name}
                                    index={i}
                                    alt={alt}
                                />
                            );
                        })}
                    </ImageGallery>
                </Columns>
            </Container>
        </Block>
    );
}

const Members = ({ data: { membersCurrent, membersPast }, ...props }) => {
    return (
        <Layout {...props}>
            <Title>Band Members</Title>

            {createMemberSection("Current", "Current Members", membersCurrent.members, "MembersCurrent")}

            {createMemberSection("Past", "Past Members", membersPast.members, "MembersPast")}
        </Layout>
    );
};

export default Members;